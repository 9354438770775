<template lang="pug">
  #capture-photo.step-component

    #camera(ref="camera" :class="facingMode" playsInline)

    #title-text {{ titleText }}
      p(v-if="isCameraStopped") No image? Try quiting and reentering with proper permission

    #user-vid-corners(v-if="photoType=='license'")
      #uvc-top-left.uvc-corner
      #uvc-top-right.uvc-corner
      #uvc-bottom-left.uvc-corner
      #uvc-bottom-right.uvc-corner

    icon#selfie-overlay(v-if="photoType=='selfie'" data="@icon/overlay/evidence-guideline-face.svg")

    #photo-controls(v-show="!isCameraStopped" @click="handleCameraClick")
      icon(data="@icon/photo-camera.svg")

    canvas#photo-canvas(v-show="false", ref="photoCanvas")
</template>

<script>
import { mapState } from 'vuex'
import { createLocalVideoTrack } from 'twilio-video'
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'

export default {
  props: {
    photoType: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      required: true,
    },
    facingMode: {
      type: String,
      required: true,
    },
    filePrefix: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      isCameraStopped: false,
      stepTimeStampAmplitude: Date.now()
    }
  },

  computed: {
    ...mapState({
      localVideoTrack: state => state.twilio.localVideoTrack,
    }),
  },

  watch: {},

  beforeCreate() {
    this.$app.forceLandscape = true
  },

  mounted() {
    this.getCamera()
  },

  methods: {
    getCamera,
    gotoNext,
    handleCameraClick,
    handlePhotoApproved,
    handleNotAllowedError,
    handleOtherErrors,
    handleRejection,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function handleNotAllowedError() {
  this.$modal.open('UpdateBrowserPermissions', {
    retry: () => {
      this.getCamera()
    },
  })
}

function handleOtherErrors() {
  this.$modal.open('PermissionsTryAgain', {
    emailSubject: 'Kit app: Could not get mic/camera',
    retryAction: () => {
      this.getCamera()
    },
  })
}

function handleRejection(event) {
  if (event.reason.name == 'NotAllowedError') {
    this.handleNotAllowedError()
  } else {
    this.handleOtherErrors()
  }

  event.preventDefault()
}

function getCamera() {
  window.addEventListener('unhandledrejection', this.handleRejection)

  const videoPromise = this.localVideoTrack
    ? Promise.resolve(this.localVideoTrack)
    : createLocalVideoTrack({ facingMode: this.facingMode })

  videoPromise
    .then(track => {
      if (!this.localVideoTrack) {
        this.$store.commit('twilio/SET_LOCAL_VIDEO_TRACK', track)
      }

      track.restart({ facingMode: this.facingMode })

      track.on('stopped', () => {
        this.isCameraStopped = true
      })

      track.on('started', () => {
        this.isCameraStopped = false
      })

      const cameraEl = document.getElementById('camera')

      if (camera.getElementsByTagName('video').length == 0) {
        cameraEl.appendChild(this.localVideoTrack.attach())
        this.cameraEl = cameraEl.getElementsByTagName('video')[0]
      }
    })
    .catch(error => {
      if (error.name == 'NotAllowedError') {
        this.handleNotAllowedError()
      } else {
        this.handleOtherErrors()
      }
    })
}

function gotoNext() {
  let currentPrefix = this.filePrefix
  if(currentPrefix === "front-of-id") {
    currentPrefix = "FrontID"
  }
  else if(currentPrefix === "back-of-id") {
    currentPrefix = "BackID"
  }
  else if(currentPrefix === "selfie") {
    currentPrefix = "Selfie"
  }

  const eventTitle = `CapturePhoto${currentPrefix}`
  const eventProperties = {
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    sku: this.$store.getters['user/getSku'],
    barcode: this.$store.getters['user/getBarcodeId'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    segment_time: Date.now() - this.stepTimeStampAmplitude,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  });

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })

  this.stepTimeStampAmplitude = Date.now()
  window.removeEventListener('unhandledrejection', this.handleRejection)
  this.$emit('complete', true)
}

function handleCameraClick() {
  if (!this.localVideoTrack) {
    this.getCamera()
    return
  }

  var video = this.cameraEl
  var photoCanvas = this.$refs.photoCanvas
  var context = photoCanvas.getContext('2d')

  // set the canvas to have the same dimensions as the video
  context.canvas.width = video.videoWidth
  context.canvas.height = video.videoHeight

  var vRatio = (photoCanvas.height / video.videoHeight) * video.videoWidth
  context.clearRect(0, 0, photoCanvas.height, photoCanvas.width)
  context.drawImage(video, 0, 0, vRatio, video.videoHeight)

  // save image to localstorage so photo confirmation modal can display it
  localStorage.setItem('capturedImage', photoCanvas.toDataURL())

  this.$modal.open('PhotoConfirmation', {
    onComplete: this.handlePhotoApproved,
    rotateImage: this.facingMode == 'user',
    onCancel: () => {
      // do nothing
    },
  })
}

function handlePhotoApproved() {
  this.$refs.photoCanvas.toBlob(blob => {
    this.$store
      .dispatch('user/upload', {
        file: blob,
        fileName: `${this.filePrefix}-${Date.now()}.png`,
      })
      .then(() => {
        localStorage.removeItem('capturedImage')
      })
  }, 'image/png')

  // detach the video track
  const mediaElements = this.localVideoTrack.detach()
  mediaElements.forEach(mediaElement => mediaElement.remove())

  this.gotoNext()
}
</script>
